<template>
  <b-container class="page-container">
    <bread-crumbs
      :bread-crumbs="breadCrumbs"
      :currentpage="
        this.$t('editSub.changeDetailsTitle', {
          firstname: form.firstname,
          lastname: form.lastname
        })
      "
    />
    <b-form @submit.prevent="updateSub">
      <h1>
        {{
          $t('editSub.changeDetailsTitle', {
            firstname: form.firstname,
            lastname: form.lastname
          })
        }}
      </h1>

      <b-row>
        <b-col sm="12" lg="8">
          <b-card>
            <h4>{{ $t('editSub.editSubSubTitle') }}</h4>

            <b-row>
              <b-col cols="6" class="pb-3">
                <label>
                  {{ $t('firstname') }}
                  <span class="text-danger">*</span>
                </label>
                <b-form-input v-model="form.firstname" required></b-form-input>
              </b-col>
              <b-col cols="6" class="pb-3">
                <label>
                  {{ $t('lastname') }}
                  <span class="text-danger">*</span>
                </label>
                <b-form-input v-model="form.lastname" required></b-form-input>
              </b-col>
              <b-col cols="6" class="pb-3">
                <label>
                  {{ $t('emailaddress') }}
                  <span class="text-danger">*</span>
                </label>
                <b-form-input v-model="form.email" required></b-form-input>
              </b-col>
              <b-col cols="6">
                <label>
                  {{ $t('gsm') }}
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.CONTACT_MobilePhone"
                  oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                  required
                ></b-form-input>
              </b-col>
              <b-col cols="6">
                <label>
                  {{ $t('telephoneNumber') }}
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.tel"
                  oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                  required
                ></b-form-input>
              </b-col>
              <b-col cols="12"> </b-col>
            </b-row>
          </b-card>

          <b-card class="mt-5">
            <b-row>
              <b-col>
                <h2 class="pt-2">{{ $t('confirm') }}?</h2>
              </b-col>
              <b-col class="text-right">
                <b-button variant="primary" type="submit">
                  {{ $t('saveChanges') }}
                  <icon-spinner v-if="processing" />
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import { modifySub } from '@/services/AuthorizationService'
import IconSpinner from '@/components/icons/IconSpinner'
export default {
  components: { IconSpinner },
  data() {
    return {
      form: {
        email: '',
        password: null,
        confirmPassword: null,
        tel: '',
        firstname: '',
        lastname: '',
        CONTACT_MobilePhone: ''
      },
      registering: false,
      formFilled: false,
      processing: false,
      subUser: {},
      breadCrumbs: [
        { title: this.$t('breadcrumbs-title'), path: '/' },
        { title: this.$t('myaccount'), path: '/account' }
      ]
    }
  },
  computed: {
    subUsers: function() {
      return this.$store.getters.subUsers
    },
    passwordConfirmState() {
      if (this.form.password != '' && this.form.password != null) {
        if (this.form.password == this.form.confirmPassword) {
          return true
        }
        return false
      }
      return null
    }
  },
  async created() {
    this.subUser = this.subUsers.find(obj => {
      return obj.UserName === this.$route.params.username
    })
    this.form = {
      email: this.subUser.Email,
      street: this.subUser.CUST_Street,
      city: this.subUser.CUST_City,
      houseNumber: this.subUser.CUST_HouseNumber,
      postcode: this.subUser.CUST_ZipCode,
      tel: this.subUser.Tel,
      firstname: this.subUser.FirstName,
      lastname: this.subUser.LastName,
      CONTACT_MobilePhone: this.subUser.MobilePhone
    }
  },
  methods: {
    updateSub: async function() {
      this.processing = true
      // if (Object.values(this.form).some(x => x !== null && x !== '')) {
      //   alert('not all fields are filled')
      // }
      const result = await modifySub({
        email: this.form.email,
        password: this.form.password,
        confirmPassword: this.form.confirmPassword,
        street: this.form.street,
        city: this.form.city,
        houseNumber: this.form.houseNumber,
        postcode: this.form.postcode,
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        contactID: this.subUser.ContactID,
        userName: this.subUser.UserName,
        tel: this.form.tel,
        CONTACT_MobilePhone: this.form.CONTACT_MobilePhone
      })

      if (result !== null) {
        this.$router.push({
          name: 'account',
          query: { message: this.$t('InformationSuccesfullyModified') }
        })
      }
    }
  }
}
</script>

<style></style>
